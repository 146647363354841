<template>
  <fieldset class="requisites form-fields-group">
    <ul class="form-fields-list">
      <li class="form-fields-list__item">
        <x-form-item
          v-slot="validationData"
          label="ФИО"
          name="ФИО"
          rules="required"
        >
          <x-input
            id="name"
            :value="value.name"
            placeholder="Введите ФИО"
            v-bind="validationData"
            @input="onInput($event, 'name')"
          />
        </x-form-item>
      </li>

      <li class="form-fields-list__item">
        <phone-field
          :value="value.phone"
          @input="onInput($event, 'phone')"
        />
      </li>

      <li class="form-fields-list__item">
        <x-form-item
          v-slot="{ errors, ...validationData }"
          label="Email"
          name="Email"
          rules="required|email"
        >
          <x-input
            id="email"
            :value="value.email"
            placeholder="Введите email"
            v-bind="validationData"
            :failed="!!emailError || !!errors.length"
            @input="email => onInput(email.toLowerCase(), 'email')"
          />
          <span class="x-form-item__errors">
            {{ errors.length ? '' : emailError }}
          </span>
        </x-form-item>
      </li>
    </ul>
  </fieldset>
</template>

<script>
import PhoneField from '@/components/Interface/PhoneField.vue'

export default {
  components: {
    PhoneField
  },

  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    emailError: {
      type: String,
      default: ''
    }
  },

  methods: {
    onInput (value, field) {
      this.$emit('input', { value, field })
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
