<template>
  <validation-observer v-slot="{ handleSubmit }">
    <seller-form
      class="user"
      @submit="handleSubmit(onSubmit)"
    >
      <requisites
        :value="form"
        :email-error="emailErrorMsg"
        @input="onInput"
      />

      <x-divider class="user__divider" />

      <connection
        :value="form"
        :clients="clientList"
        :roles="rolesList"
        @select:client="onClientSelect"
        @select:role="onRoleSelect"
        @delete:role="onRoleDelete"
        @delete:client="onClientDelete"
      />

      <div class="user__actions">
        <x-btn
          :to="$route.meta.back"
          color="gray"
        >
          Отменить
        </x-btn>

        <x-btn
          :loading="isSubmitProccessed"
          type="submit"
        >
          {{ isEdit ? 'Сохранить' : 'Пригласить' }}
        </x-btn>
      </div>

      <success-create-modal />
    </seller-form>
  </validation-observer>
</template>

<script>
import SellerForm from '@/components/Interface/Form.vue'
import Requisites from '@/components/Settings/Permissions/Forms/User/Requisites.vue'
import Connection from '@/components/Settings/Permissions/Forms/User/Connection.vue'
import SuccessCreateModal from '@/components/Modals/SuccessCreateModal.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('settings/permissions')

export default {
  components: {
    SellerForm,
    Requisites,
    Connection,
    SuccessCreateModal
  },

  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },

  data () {
    return {
      form: {
        name: '',
        phone: '+7',
        email: '',
        clients: []
      },
      emailErrorMsg: '',
      isSubmitProccessed: false
    }
  },

  computed: {
    ...mapState([
      'clientList',
      'rolesList'
    ]),

    isEdit () {
      return !!this.id
    }
  },

  created () {
    this.fetchData()
  },

  methods: {
    ...mapActions([
      'getUser',
      'getRolesList',
      'getClientList',
      'updateUser',
      'createUser'
    ]),

    onInput ({ field, value }) {
      this.form[field] = value
    },

    onClientSelect ({ client, idx }) {
      this.$set(this.form.clients, idx, {
        client,
        related_shop_and_roles: client.related_shop_and_roles || null
      })
    },

    onClientDelete (idx) {
      this.$delete(this.form.clients, idx)
    },

    onRoleSelect ({ shop, role, client }) {
      const relatedClient = this.form.clients.find(c => c.client.id === client.client.id)
      let relatedShop = relatedClient.related_shop_and_roles?.find(s => s.id === shop.id)

      if (!relatedShop) {
        relatedShop = client.client.shops.find(s => s.id === shop.id)

        if (!relatedClient.related_shop_and_roles) {
          this.$set(relatedClient, 'related_shop_and_roles', [])
        }

        relatedClient.related_shop_and_roles.push(relatedShop)
      }

      this.$set(relatedShop, 'role', role)
    },

    onRoleDelete ({ shop, client }) {
      const relatedClient = this.form.clients.find(c => c.client.id === client.client.id)
      const relatedShop = relatedClient.related_shop_and_roles.find(s => s.id === shop.id)
      relatedShop.role = null
    },

    fetchData () {
      if (this.isEdit) {
        this.fetchUser()
      }

      if (!this.clientList.length) {
        this.getClientList()
      }

      if (!this.rolesList.length) {
        this.getRolesList()
      }
    },

    async fetchUser () {
      try {
        this.isLoading = true
        const user = await this.getUser(this.id)

        this.form = user
        this.$route.meta.heading = user.name || user.email
      } finally {
        this.isLoading = false
      }
    },

    async onSubmit () {
      try {
        this.isSubmitProccessed = true
        this.emailErrorMsg = ''

        if (this.isEdit) {
          await this.updateUser(this.form)
          this.$toast.success('Данные сохранены')
          this.$router.push(this.$route.meta.back)
          return
        }

        await this.createUser(this.form)
        this.$modal.show('success-create-modal', {
          email: this.form.email,
          back: this.$route.meta.back
        })
      } catch (e) {
        const [errorMsg] = e.response?.data?.email
        if (errorMsg) {
          this.emailErrorMsg = errorMsg
        }
      } finally {
        this.isSubmitProccessed = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .user
    width 430px

    &__divider
      margin 20px 0

    &__actions
      display flex
      align-items center
      justify-content flex-end
      margin-top 20px
</style>
