<template>
  <fieldset class="connection form-fields-group">
    <ul class="form-fields-list">
      <li class="form-fields-list__item">
        <ul
          v-if="value.clients.length"
          class="connection__client"
        >
          <li
            v-for="(client, idx) in value.clients"
            :key="client.client.id"
          >
            <x-divider
              v-if="idx !== 0"
              class="connection__divider"
            />

            <div class="connection__client-select">
              <client-select
                :value="client.client"
                :options="unselectedClients"
                :clearable="false"
                @input="client => onClientSelect(client, idx)"
              />

              <delete-button
                class="connection__action_delete"
                @click="onClientDelete(idx)"
              />
            </div>

            <ul class="connection__client-shops">
              <li
                v-for="shop in getClientShops(client)"
                :key="shop.id"
                class="connection__client-shop"
              >
                <x-form-item
                  label="Магазин"
                >
                  <x-input
                    readonly
                    width="210px"
                    :value="shop.name"
                  />
                </x-form-item>

                <x-divider
                  color="gray"
                  class="connection__client-divider"
                />

                <role-select
                  width="210px"
                  :value="shop.role"
                  :roles="roles"
                  @input="role => onRoleSelect({ shop, role, client })"
                />
              </li>
            </ul>
          </li>
        </ul>

        <x-divider
          v-if="value.clients.length && showAddBtn"
          class="connection__divider"
        />

        <client-select
          v-if="!value.clients.length"
          :value="null"
          :options="unselectedClients"
          @input="client => onClientSelect(client, 0)"
        />

        <x-btn
          v-if="showAddBtn"
          ghost
          type="button"
          prepend="plus"
          @click="onAddClientClick"
        >
          Добавить клиента
        </x-btn>
      </li>
    </ul>
  </fieldset>
</template>

<script>
import ClientSelect from '@/components/Settings/Permissions/ClientSelect.vue'
import RoleSelect from '@/components/Settings/Permissions/RoleSelect.vue'
import DeleteButton from '@/components/Interface/DeleteButton.vue'

export default {
  components: {
    ClientSelect,
    RoleSelect,
    DeleteButton
  },

  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    clients: {
      type: Array,
      default: () => []
    },
    roles: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    unselectedClients () {
      return this.clients.filter(client => {
        return !this.value.clients.find(c => c.client.id === client.id)
      })
    },

    showAddBtn () {
      return this.value.clients.length && this.unselectedClients.length
    }
  },

  methods: {
    onClientSelect (client, idx) {
      this.$emit('select:client', { client, idx })
    },

    onRoleSelect ({ shop, role, client }) {
      if (!role) {
        this.$emit('delete:role', { shop, client })
        return
      }
      this.$emit('select:role', { shop, role, client })
    },

    onClientDelete (idx) {
      this.$emit('delete:client', idx)
    },

    onAddClientClick () {
      const [client] = this.unselectedClients
      this.$emit('select:client', {
        client,
        idx: this.value.clients.length
      })
    },

    getClientShops (value) {
      const { client, related_shop_and_roles: relatedShops } = value
      if (!relatedShops) return client.shops
      if (!client.shops) return relatedShops
      const filteredShops = client.shops.filter(s => !relatedShops.includes(s.id))
      return Array.from(new Set([...filteredShops, ...relatedShops]))
    }
  }
}
</script>

<style lang="stylus" scoped>
  .connection
    &__client
      list-style none

    &__client-select
      display flex
      align-items center

    &__client-shop
      display flex
      align-items center
      margin-top 10px

    &__client-divider
      width 100%
      flex-basis auto
      margin-top 18px

    &__divider
      margin 20px 0

    &__action
      &_delete
        margin-right -32px
        margin-top 18px
</style>
