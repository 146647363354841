<template>
  <x-form-item
    label="Роль"
  >
    <x-select
      v-bind="$attrs"
      :value="value"
      :options="roles"
      label="title"
      track-by="id"
      placeholder="Роль не выбрана"
      :preselect-first="false"
      @input="$emit('input', $event)"
    />
  </x-form-item>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    roles: {
      type: Array,
      default: () => []
    }
  }
}
</script>
